import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-pattern-input-notice', {
	attributes: [
		{
			attribute: 'pattern-invalid-message',
			type: 'string',
		},
		{
			attribute: 'empty-message',
			type: 'string',
		},
	],
	controller: class extends BaseController {

		bind() {
			const inputs = Array.from( this.el.querySelectorAll( 'input' ) );

			inputs.forEach( ( input ) => {
				input.addEventListener( 'input', () => {
					input.setCustomValidity( '' );
					input.checkValidity();
				} );

				input.addEventListener( 'invalid', () => {
					console.log( 'invalid check' );
					if ( '' === input.value ) {
						input.setCustomValidity( this.emptyMessage );
					} else {
						input.setCustomValidity( this.patternInvalidMessage );
					}
				} );
			} );
		}
	},
} );
