import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';
// import { getQueryParametersByName } from '../helpers/query-params.js'
// import { periodToFromAndUntil } from '../helpers/date.js'

defineCustomElement( 'mr-date-select', {
	attributes: [],
	controller: class extends BaseController {
		render() {
			this.yearSelect = this.el.querySelector( '#js-year' );
			this.yearSelect.value = this.year;
			this.monthSelect = this.el.querySelector( '#js-month' );
			this.monthSelect.value = this.month;
			this.daySelect = this.el.querySelector( '#js-day' );
			this.daySelect.value = this.day;
		}

		bind() {
			this.valueChangeHandlerYear = () => {
				this.year = this.yearSelect.value;
				this.setDayOptions();
			};

			this.valueChangeHandlerMonth = () => {
				this.month = this.monthSelect.value;
				this.setDayOptions();
			};

			this.valueChangeHandlerDay = () => {
				this.day = this.daySelect.value;
				this.setDayOptions();
			};

			this.yearSelect.addEventListener( 'change', this.valueChangeHandlerYear );
			this.monthSelect.addEventListener( 'change', this.valueChangeHandlerMonth );
			this.daySelect.addEventListener( 'change', this.valueChangeHandlerDay );
		}

		unbind() {
			this.yearSelect.removeEventListener( 'change', this.valueChangeHandlerYear );
			this.monthSelect.removeEventListener( 'change', this.valueChangeHandlerMonth );
			this.daySelect.removeEventListener( 'change', this.valueChangeHandlerDay );
		}

		get year() {
			if ( this._year !== undefined ) {
				return this._year;
			}

			this._year = this.yearSelect.value;

			return this._year;
		}

		set year( v ) {
			if ( v ) {
				this._year = v;
			} else {
				this._year = '';
				this.month = '';
			}

			this.yearSelect.value = this._year;
		}

		get month() {
			if ( this._month !== undefined ) {
				return this._month;
			}

			this._month = this.monthSelect.value;

			return this._month;
		}

		set month( v ) {
			if ( !this.year && v ) {
				this.year = `${new Date().getFullYear()}`;
			}

			if ( v ) {
				this._month = ( '00' + v ).slice( -2 );
			} else {
				this._month = '';
				this.day = '';
			}

			this.monthSelect.value = this._month;
		}

		get day() {
			if ( this._day !== undefined ) {
				return this._day;
			}

			this._day = this.daySelect.value;

			return this._day;
		}

		set day( v ) {
			if ( !this.month && v ) {
				this.month = ( new Date().getMonth() + 1 );
			}

			if ( v ) {
				this._day = ( '00' + v ).slice( -2 );
			} else {
				this._day = '';
			}

			this.daySelect.value = this._day;
		}

		setDayOptions() {
			// First let's reset the classNames
			this.daySelect.classList.remove( 'days-31' );
			this.daySelect.classList.remove( 'days-30' );
			this.daySelect.classList.remove( 'days-29' );
			this.daySelect.classList.remove( 'days-28' );

			if ( isNaN( this.year * 1 ) ) {
				this.daySelect.classList.add( 'days-31' );

				return;
			}

			if ( isNaN( this.month * 1 ) ) {
				this.daySelect.classList.add( 'days-31' );

				return;
			}

			if ( '01' === this.month || '03' === this.month || '05' === this.month || '07' === this.month || '08' === this.month || '10' === this.month || '12' === this.month ) {
				this.daySelect.classList.add( 'days-31' );
			} else if ( '04' === this.month || '06' === this.month || '09' === this.month || '11' === this.month ) {
				this.daySelect.classList.add( 'days-30' );
				if ( '30' < this.day ) {
					this.day = '30';
				}
			} else if ( 0 === ( this.year * 1 ) % 4 ) {
				this.daySelect.classList.add( 'days-29' );
				if ( '29' < this.day ) {
					this.day = '29';
				}
			} else {
				this.daySelect.classList.add( 'days-28' );
				if ( '28' < this.day ) {
					this.day = '28';
				}
			}
		}
	},
} );
