import '@mrhenry/wp--bugsnag-config';

import MrDeltaTime from '@mrhenry/wp--mr-delta-time';
customElements.define( 'mr-delta-time', MrDeltaTime );

import './modules/accordion';
import './modules/accordion-v2';
import './modules/date-select';
import './modules/jump-to-slideshow-item';
import './modules/modal';
import './modules/mr-fly-out-menu';
import './modules/mr-fly-out-menu-trigger';
import './modules/pattern-input-notice';
import './modules/slideshow';
