import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-slideshow', {
	attributes: [
		{
			attribute: 'auto',
			type: 'int',
		},
		{
			attribute: 'current',
			type: 'int',
		},
	],
	controller: class extends BaseController {
		currentChangedCallback( from, to ) {
			this.emit( 'mr-slideshow:current', {
				current: to,
			} );

			this.render();
		}

		autoChangedCallback() {
			this.start();
		}

		start() {
			this.stop();

			if ( this.auto && 0 < this.auto ) {
				this.looper = setInterval( () => {
					this.next();
				}, this.auto );
			}
		}

		stop() {
			if ( this.looper ) {
				clearInterval( this.looper );
				this.looper = null;
			}
		}

		next() {
			if ( this.current < this.elements.items.length - 1 ) {
				this.current = this.current + 1;
			} else {
				this.current = this.elements.items.length - 1;
			}
		}

		previous() {
			if ( 0 < this.current ) {
				this.current = this.current - 1;
			} else {
				this.current = 0;
			}
		}

		resolve() {
			if ( 1 === this.el.querySelectorAll( '.js-slideshow-item' ).length ) {
				// Keep hanging, don't activate if empty
				return new Promise( () => {} );
			}

			return super.resolve();
		}

		init() {
			this.elements = {};
			this.elements.items = Array.from( this.el.querySelectorAll( '.js-slideshow-item' ) );
			this.elements.parent = this.el.querySelector( '.js-slideshow-parent' );
			this.elements.track = this.el.querySelector( '.js-slideshow-track' );

			this.createNav();

			this.emit( 'mr-slideshow:current', {
				current: this.current,
			} );

			this.start();
		}

		bind() {
			this.on( 'mr-jump-to-slideshow-item:jump', ( e ) => {
				if ( e.detail.slideshowId === this.el.getAttribute( 'id' ) ) {
					this.current = e.detail.position;
				}
			}, window, {
				passive: true,
			} );

			this.on( 'keydown', ( e ) => {
				if ( 37 === e.keyCode ) {
					this.previous();
				} else if ( 39 === e.keyCode ) {
					this.next();
				}
			}, window, {
				passive: true,
			} );

			this.on( 'click .js-slideshow-item', ( e, slideshowItem ) => {
				const clickX = e.clientX;
				const boundingBox = slideshowItem.getBoundingClientRect();
				const clickWithinRightHalf = 0.5 < ( ( clickX - boundingBox.left ) / boundingBox.width );

				if ( clickWithinRightHalf ) {
					this.next();
				} else {
					this.previous();
				}
			} );

			this.navigation.forEach( ( item, index ) => {
				this.on( 'click', ( e ) => {
					if ( index !== this.current ) {
						e.preventDefault();
						this.current = index;
					}
				}, item );
			} );

			const touch = {
				startX: 0,
				threshold: 100,
				allowedTime: 400,
				startTime: 0,
			};

			this.on( 'touchstart', ( e ) => {
				touch.startX = e.changedTouches[0].screenX;
				touch.startTime = new Date().getTime();
			} );

			this.on( 'touchend', ( e ) => {
				const distX = e.changedTouches[0].screenX - touch.startX;
				if ( new Date().getTime() - touch.startTime <= touch.allowedTime &&
					Math.abs( distX ) >= touch.threshold ) {
					if ( 0 > distX ) {
						this.next();
					} else {
						this.previous();
					}
				}
			} );
		}

		render() {
			this.navigation.forEach( ( item, index ) => {
				if ( index === this.current ) {
					item.classList.add( 'is-active' );
				} else {
					item.classList.remove( 'is-active' );
				}
			} );

			this.scroll();
		}

		createNav() {
			const fragment = document.createDocumentFragment();
			const nav = document.createElement( 'div' );
			nav.classList.add( 'slideshow__navigation' );

			this.navigation = this.elements.items.map( ( item, index ) => {
				const navItem = document.createElement( 'button' );
				navItem.textContent = index + 1;
				Object.assign( item.dataset, {
					galleryIndex: index,
				} );
				nav.appendChild( navItem );

				return navItem;
			} );

			fragment.appendChild( nav );
			this.elements.parent.appendChild( fragment );
		}

		scroll() {
			if ( !this.elements.items.length ) {
				return;
			}

			const position = this.elements.items[this.current].offsetLeft;
			const transform = `translateX(-${Math.round( position )}px)`;

			Object.assign( this.elements.track.style, {
				transform: transform,
				webkitTransform: transform,
			} );
		}

		destroy() {
			this.stop();
			super.destroy();
		}
	},
} );
