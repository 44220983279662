import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-accordion', {
	attributes: [],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.checkboxes = Array.from( this.el.querySelectorAll( 'input[type="checkbox"]' ) );
		}

		bind() {
			this.elements.checkboxes.forEach( ( checkbox ) => {
				this.on( 'change', () => {
					if ( false === checkbox.checked ) {
						return;
					}

					const id = checkbox.getAttribute( 'id' );
					this.closeOthers( id );
				}, checkbox );

			} );
		}

		closeOthers( selfId ) {
			this.elements.checkboxes.forEach( ( checkbox ) => {
				const checkboxId = checkbox.getAttribute( 'id' );

				if ( selfId !== checkboxId && true === checkbox.checked ) {
					checkbox.checked = false;
				}
			} );
		}
	},
} );
