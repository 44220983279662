import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-jump-to-slideshow-item', {
	attributes: [],
	controller: class extends BaseController {
		resolve() {
			// don't wait for DOM ready
			return Promise.resolve( true );
		}

		init() {
			return Promise.resolve();
		}

		bind() {
			this.on( 'click .js-jump-to-slideshow-item-link', ( e, target ) => {
				const slideshowId = target.getAttribute( 'data-slideshow-id' );
				const position = target.getAttribute( 'data-jump-to-position' );

				this.el.dispatchEvent( new CustomEvent( 'mr-jump-to-slideshow-item:jump', {
					bubbles: true,
					cancelable: true,
					detail: {
						position: position,
						slideshowId: slideshowId,
					},
				} ) );
			} );
		}
	},
} );
