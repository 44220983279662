import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-modal', {
	attributes: [],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.body = this.el.querySelector( '.js-modal-body' );
			this.elements.close = this.el.querySelector( '.js-modal-close' );
			this.elements.content = this.el.querySelector( '.js-modal-content' );
			this.elements.triggers = Array.from( this.el.querySelectorAll( '.js-modal-trigger' ) );

			if ( this.elements.content ) {
				this.elements.content.removeAttribute( 'style' );
			}
		}

		bind() {
			// close modal when user presses esc (keycode 27) key
			this.on( 'keydown', ( e ) => {
				const content = this.el.querySelector( '.js-modal-content' );
				if ( !content ) {
					return;
				}

				if ( 27 === e.keyCode && content.classList.contains( 'is-ready' ) && content.classList.contains( 'is-showing' ) ) {
					this.close();
				}
			}, window, {
				passive: true,
			} );

			// close modal when user clicks outside of modal content
			this.on( 'click', ( e, target ) => {
				e.preventDefault();
				e.stopPropagation();

				const content = this.el.querySelector( '.js-modal-content' );
				if ( !content ) {
					return;
				}

				const body = this.el.querySelector( '.js-modal-body' );
				if ( !body ) {
					return;
				}

				if ( !body.contains( target ) && content.classList.contains( 'is-ready' ) && content.classList.contains( 'is-showing' ) ) {
					this.close();
				}
			}, this.el );

			// close modal when users click dedicated close-button
			this.on( 'click .js-modal-close', ( e ) => {
				e.preventDefault();
				e.stopPropagation();

				const content = this.el.querySelector( '.js-modal-content' );
				if ( !content ) {
					return;
				}

				// make sure the style was removed
				content.removeAttribute( 'style' );

				if ( content.classList.contains( 'is-ready' ) && content.classList.contains( 'is-showing' ) ) {
					this.close();
				}
			}, this.el );

			// open modal when users click on a dedicated modal-trigger
			const triggers = Array.from( this.el.querySelectorAll( '.js-modal-trigger' ) );
			triggers.forEach( ( trigger ) => {
				this.on( 'click', ( e ) => {
					e.preventDefault();
					e.stopPropagation();

					const content = this.el.querySelector( '.js-modal-content' );
					if ( !content ) {
						return;
					}

					// make sure the style was removed
					content.removeAttribute( 'style' );

					if ( !content.classList.contains( 'is-ready' ) && !content.classList.contains( 'is-showing' ) ) {
						this.open();
					}
				}, trigger );
			} );

			// open modal on mr-jump-to-slideshow-item:jump event
			// if modal contains targetted slideshow
			this.on( 'mr-jump-to-slideshow-item:jump', ( e ) => {
				const targetSlideshow = this.el.querySelector( `#${e.detail.slideshowId}` );
				if ( !targetSlideshow ) {
					return;
				}

				const content = this.el.querySelector( '.js-modal-content' );
				if ( !content ) {
					return;
				}

				if ( !content.classList.contains( 'is-ready' ) && !content.classList.contains( 'is-showing' ) ) {
					this.open();
				}
			}, window, {
				passive: true,
			} );
		}

		close() {
			const content = this.el.querySelector( '.js-modal-content' );
			if ( !content ) {
				return;
			}

			content.classList.remove( 'is-showing' );

			setTimeout( () => {
				content.classList.remove( 'is-ready' );
			}, 96 );
		}

		open() {
			const content = this.el.querySelector( '.js-modal-content' );
			if ( !content ) {
				return;
			}

			content.classList.add( 'is-ready' );

			setTimeout( () => {
				content.classList.add( 'is-showing' );
			}, 32 );
		}
	},
} );
